import AppLogoIcon from './app-logo-icon';
import Link from '@/components/link';

export default function AppLogo() {
    return (
        <Link href="/" prefetch className="flex items-center gap-1">
            <div className=" flex aspect-square size-8 items-center justify-center rounded-md">
                <AppLogoIcon />
                {/*<AppLogoIcon className="size-5 fill-current text-white dark:text-black" />*/}
            </div>
            <div className="ml-1 grid flex-1 text-left text-sm">
                <span className="mb-0.5 truncate leading-none font-semibold">Learnable</span>
            </div>
        </Link>
    );
}
